import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import msgReducer from "./Store/Reducers/message_reducer";


declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
  }
  const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
function configureStore() {
  const rootReducer = combineReducers({
    message: msgReducer,
  });
  const store = createStore(
    rootReducer,
    composeEnhances(applyMiddleware(thunk))
  );

  return store;
}
const store = configureStore();
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>
, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
