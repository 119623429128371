//Production Environment
export const API = "https://eritstaging.herokuapp.com"
export const webSocketAPI = "eritstaging.herokuapp.com";
//Staging Environment
// export const API = "https://+eritstaging.herokuapp.com"


// const Test = "https://eritstaging.herokuapp.com"


const Live = "https://eritapp.herokuapp.com"
