import * as React from "react";
import error from "../assets/error.png";
import "./Dashboard/style.css";

const ErrorMessage = () => {
  return (
    <>
        <div className="tempunav">
            <img src={error} className="error213" alt="testt1"/>
            <div>Temporarily unavailable</div>
            <div>visit <a href="https://clarity.yudimy.com"> clarity.yudimy.com</a></div>
        </div>
    </>
  );
};
export default ErrorMessage;
